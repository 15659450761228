import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

interface Props {

}

export default function Header({ }: Props): ReactElement {
  return (
    <header className="relative bg-white">
         <div className="max-w-3xl mx-auto px-4 py-6">
      <div className="w-full opacity-0 animate-fade-in-up animation-delay-50">
        <div className="flex flex-col sm:flex-row items-baseline">
          <span className="font-bold text-3xl mb-2 sm:mb-0">Krikke 👋</span>
          <span className="text-lg sm:px-4 source-code-pro-light">Christophe van Eerdt</span>
        </div>
          {/*<nav className="flex nav">
            <ul className="flex">
              <li><HashLink to="/home2" className="block py-6 px-4 font-bold"></HashLink></li>
              <li><HashLink to="/#work" className="block py-6 px-4 font-bold">Work</HashLink></li>
              <li><Link to="/about" className="block py-6 px-4 font-bold">About</Link></li> 
            </ul>
          </nav>*/}
        </div>
      </div>
    </header>
  )
}