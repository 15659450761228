import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MoneyIndicator from './MoneyIndicator';


// Define the project type
interface Project {
  id: string;
  logo: string;
  logoColor: string; 
  title: string;
  description: string;
  year: string;
  status: 'done' | 'archived' | 'planning' | 'active';
  insight?: string;
  moneyDetails?: string;
  moneyTooltip?: string; 
  metric: string;
}

interface Props {
  // You can add any props needed
}

export default function Home({}: Props) {
  // Change default to false to hide details by default
  const [showDetails, setShowDetails] = useState(false);
  
  // State to track which projects are expanded individually
  const [expandedProjects, setExpandedProjects] = useState<Record<string, boolean>>({});
  
  // Function to toggle individual project expansion
  const toggleProjectDetails = (projectId: string) => {
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId]
    }));
  };
  
  // Function to toggle all details and reset individual expansions when toggling to simple view
  const toggleAllDetails = () => {
    const newShowDetails = !showDetails;
    setShowDetails(newShowDetails);
    
    // If switching to simple view, reset all individually expanded projects
    if (!newShowDetails) {
      setExpandedProjects({});
    }
  };
  
  // Check if a project should show details (either global toggle or individually expanded)
  const shouldShowDetails = (projectId: string) => {
    return showDetails || expandedProjects[projectId];
  };
  
  // Project data
  const projects: Project[] = [
    {
      id: 'intimelt',
      logo: 'IM',
      logoColor: '#D7A06A',
      title: 'Intimelt',
      description: 'An intimate massage butter to help couples connect.',
      year: '2024 - 2025',
      status: 'active',
      moneyDetails: '',
      moneyTooltip: 'First 7 presales. Figuring out production.',
      insight: '💡 Making a unique product takes time and money.',
      metric: '🚀 First 7 presales.',
    },
    {
      id: 'logdrops',
      logo: 'LD',
      logoColor: '#57A9AD',
      title: 'LogDrops',
      description: 'Log your poop to improve your gut health.',
      year: '2025',
      status: 'active',
      moneyDetails: '',
      moneyTooltip: 'Learning React project, no revenue yet.',
      insight: '💡 Friends wanted my gut-tracking spreadsheet, so why not make it an app?',
      metric: '🚀 Go-live in March 2025.',
    },
    {
      id: 'freelance',
      logo: 'FD',
      logoColor: '#3b82f6', // Blue
      title: 'Freelance Design',
      description: 'Building WordPress sites for SMBs through word-of-mouth referrals.',
      year: '2010 - 2025',
      status: 'active',
      moneyDetails: '',
      moneyTooltip: 'Steady side-income for 15+ years.',
      insight: '💡 Meaningful business impact with client relationships of 10+ years.',
      metric: ''
    },
    {
      id: 'bundl',
      logo: 'VB',
      logoColor: '#000000',
      title: 'Venture Builder at Bundl',
      description: 'Building Corporate Ventures with global brands.',
      year: '2021 - 2024',
      status: 'done',
      moneyDetails: '',
      moneyTooltip: 'Full-time salary.',
      insight: '💡 The right people and focus get stuff done.',
      metric: ''
    },
    {
      id: 'bundl-kollegein',
      logo: 'KG',
      logoColor: '#027D87',
      title: 'Kollege:in',
      description: 'Helping doctors share interactive patient cases.',
      year: '2024',
      status: 'done',
      moneyDetails: 'via Bundl',
      moneyTooltip: 'Bundl project.',
      insight: '💡 Design before building and build it right.',
      metric: ''
    },
    {
      id: 'bundl-pilly',
      logo: 'PY',
      logoColor: '#987fd1',
      title: 'Pilly',
      description: 'Pitching proven feature impact on treatment adherence.',
      year: '2023',
      status: 'done',
      moneyDetails: 'via Bundl',
      moneyTooltip: 'Bundl project.',
      insight: '💡 People only need a few key features.',
      metric: ''
    },
    {
      id: 'bundl-aipersona',
      logo: 'AI',
      logoColor: '#1e1934',
      title: 'AI Persona Video',
      description: 'Using Midjourney, Runway and ElevenLabs to create on-brand video personas.',
      year: '2023',
      status: 'done',
      moneyDetails: 'via Bundl',
      moneyTooltip: 'Bundl project.',
      insight: '💡 Creative direction and tool knowledge is essential.',
      metric: ''
    },
    {
      id: 'bundl-monthlies',
      logo: 'ML',
      logoColor: '#E86465',
      title: 'Monthlies',
      description: 'Validating and launching a menstrual care brand to the EU market.',
      year: '2022 - 2023',
      status: 'done',
      moneyDetails: 'via Bundl',
      moneyTooltip: 'Bundl project.',
      insight: '💡 Balance speed vs. quality of tests in multilingual custom Shopify.',
      metric: ''
    },
    {
      id: 'bundl-routinely',
      logo: 'RL',
      logoColor: '#774c2b',
      title: 'Routinely',
      description: 'D2C skincare market entry strategy and growth opportunity mapping.',
      year: '2021',
      status: 'done',
      moneyDetails: 'via Bundl',
      moneyTooltip: 'Bundl project.',
      insight: '💡 Systematic competitor research creates value down the road.',
      metric: ''
    }, 
    {
      id: 'aveve',
      logo: 'AV',
      logoColor: '#00936F', 
      title: 'Omnichannel at Aveve',
      description: 'Transforming the Aveve retail customer experience.',
      year: '2018 - 2021',
      status: 'done',
      moneyDetails: '',
      moneyTooltip: 'Full-time salary.',
      insight: '💡 Active and deliberate stakeholder management is crucial and not for rookies like myself.',
      metric: ''
    },
    {
      id: 'kotapp',
      logo: 'KA',
      logoColor: '#8b5cf6', 
      title: 'KotApp',
      description: 'Helping Belgian university students manage their housing & community.',
      year: '2016 - 2017',
      status: 'archived',
      moneyDetails: '',
      moneyTooltip: 'Never launched.',
      insight: '💡 Stop talking and scheming, just build it.',
      metric: ''
    },
    {
      id: 'Dapper',
      logo: 'DP',
      logoColor: '#000000',
      title: 'Dapper',
      description: 'Friends doing design & development for SMBs.',
      year: '2015 - 2017',
      status: 'archived',
      moneyDetails: '',
      moneyTooltip: 'Freelance projects.',
      insight: '💡 Design before developing.',
      metric: ''
    },
    {
      id: 'gallerybay',
      logo: 'GB',
      logoColor: '#F44900',
      title: 'MyGalleryBay',
      description: 'Development for a startup community for artists.',
      year: '2016 - 2017',
      status: 'archived',
      moneyDetails: 'via Dapper',
      moneyTooltip: 'Project based.',
      insight: '💡 Create a clear project plan.',
      metric: ''
    },
    {
      id: 'boostfarm',
      logo: 'BF',
      logoColor: '#F22900', // Amber
      title: 'Boostfarm',
      description: 'SoundCloud play service. Client acquisition via SEO.',
      year: '2013 - 2014',
      status: 'archived',
      moneyDetails: '',
      moneyTooltip: 'Digital product sales. $Up to 500 months.',
      insight: '💡 Find ways to reach more people and to sustainably scale technology.',
      metric: ''
    },
    {
      id: 'themesupport',
      logo: 'CS',
      logoColor: '#ec4899', // Pink
      title: 'Customer Support',
      description: 'Helping clients set up or modify a bestseller WordPress Theme.',
      year: '2008 - 2010',
      status: 'archived',
      moneyDetails: '',
      moneyTooltip: 'Part-time salary.',
      insight: '💡 Trading time for money. Learn to support the difficult stuff.',
      metric: ''
    },
    {
      id: 'fireworks',
      logo: 'FK',
      logoColor: '#ff3b00', // Pink
      title: 'Fireworks Krikke',
      description: 'My dad bought small fireworks and I sold them to older local kids 😎.',
      year: '14 years old',
      status: 'archived',
      moneyDetails: 'Side hustle',
      moneyTooltip: 'First sales experiences.',
      insight: '💡 Buy low, sell high.',
      metric: ''
    },
    {
      id: 'sunglasses',
      logo: 'SK',
      logoColor: '#fcc500', // Pink
      title: 'Sunglasses Krikke',
      description: 'I bought sunglasses on Ali Express and sold them to local kids 😎.',
      year: '13 years old',
      status: 'archived',
      moneyDetails: '',
      moneyTooltip: 'First sales experiences.',
      insight: '💡 Dropshipping before it was cool. Buy global, sell local.',
      metric: ''
    } /*
    {
      id: 'landing',
      logo: 'LP',
      logoColor: '#6366f1', // Indigo
      title: 'Landing Pages',
      description: 'Development of conversion-focused landing pages.',
      year: '2008',
      status: 'archived',
      moneyDetails: 'Side hustle'
    }, */ /*
    {
      id: 'wptheme',
      logo: 'TD',
      logoColor: '#ef4444', // Red
      title: 'Theme Development',
      description: 'Developed my first WordPress theme from scratch',
      year: '2008',
      status: 'archived',
      moneyDetails: 'No revenue'
    }, */ /*
    {
      id: 'mentorship',
      logo: 'WP',
      logoColor: '#14b8a6', // Teal
      title: 'Learning CSS',
      description: 'Learned HTML, CSS, and PHP from a friend I met on a gaming forum',
      year: '2007',
      status: 'archived',
      moneyDetails: 'No revenue'
    }, */ /*
    {
      id: 'htmlpage',
      logo: 'FP',
      logoColor: '#06b6d4', // Cyan
      title: 'First Page',
      description: 'Built my first HTML page embedding YouTube videos of favorite songs',
      year: '2007',
      status: 'archived',
      moneyDetails: 'No revenue'
    }, */ /* 
    {
      id: 'dogforum',
      logo: 'DF',
      logoColor: '#f97316', // Orange
      title: 'Dog Forum',
      description: 'Created a forum about dogs to convince my parents to get one',
      year: '2006',
      status: 'archived',
      moneyDetails: 'No revenue'
    } */
  ];

  // Helper function to get status class
  const getStatusClass = (status: string): string => {
    switch (status) {
      case 'active': return 'bg-blue-50 text-blue-500';
      case 'archived': return 'bg-gray-50 text-gray-500';
      case 'planning': return 'bg-amber-50 text-amber-600';
      case 'done': return 'bg-green-50 text-green-500';
      default: return '';
    }
  };

  // Helper function to capitalize status for display
  const formatStatus = (status: string): string => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  // Helper function to determine money icon 
  const getMoneyIcon = (moneyDetails: string): string => {
    return moneyDetails.toLowerCase().includes('no revenue') ? '💸' : '💰';
  };

  return (
    <div className="mt-12 md:mt-12">
      <div className="opacity-0 max-w-3xl mx-auto px-4 text-left mb-8 animate-fade-in-up animation-delay-200">
        <h2 className="text-3xl md:text-4xl font-bold leading-tight mb-4">Building cool stuff people want to pay for since 2008</h2>
        <p className="text-base md:text-lg text-gray-600 mb-2">After 15+ years of mostly building stuff for others, in 2025 I'm trying to ship more of my own product instead of dreaming, talking, or making slides about them.</p>
        <p className="text-base md:text-base text-gray-600 mb-2 py-4">Here's my messy collection of projects, jobs and hustles I can remember. Some made a bit of money, all taught me something. Originally documented for myself, hopefully inspiring to you.</p>
      </div>
      
      {/* Replace toggle switch with text button and arrows */}
      <div className="max-w-3xl mx-auto px-4 flex justify-end mb-4 opacity-0 animate-fade-in-up animation-delay-400">
        <button 
          onClick={() => toggleAllDetails()}
          className="text-xs uppercase font-bold text-gray-500 hover:text-black transition-colors flex items-center gap-1"
        >
          {showDetails ? (
            <>
              Hide details
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
            </>
          ) : (
            <>
              Show details
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </>
          )}
        </button>
      </div>
      
      <div className="max-w-3xl mx-auto flex flex-col space-y-4 mb-8">
        {projects.map((project) => (
          <div 
            key={project.id}
            className={`opacity-0 animate-fade-in-up animation-delay-400 rounded-xl shadow-sm border border-gray-300 ${shouldShowDetails(project.id) ? 'pb-5 px-4' : 'px-4'} flex flex-col transition-all duration-300 ease-in-out group hover:shadow-lg hover:border-gray-200 hover:scale-105 cursor-pointer`}
            onClick={() => toggleProjectDetails(project.id)}
          > 
            <div className="flex flex-row items-center justify-between gap-3 mb-2 mt-2">
              {/* Left side - Logo and Title */}
              <div className="flex flex-row items-center gap-2.5">
                <div 
                  className="w-6 h-6 rounded flex items-center justify-center text-white font-bold text-xs"
                  style={{ backgroundColor: project.logoColor }}
                >
                  {project.logo}
                </div>
                <h3 className="text-base md:text-lg font-semibold leading-tight">{project.title}</h3>
              </div>
              
              {/* Year - Always visible */}
              <span className="text-xs text-gray-600 bg-gray-50 px-2 py-0.5 rounded border border-gray-100 shrink-0">{project.year}</span>
            </div>
            
            {/* Description - Second row */}
            <p className="text-gray-600 text-sm md:text-base leading-normal mb-3">{project.description}</p>
            
            {/* Insight with Status and Money Details */}
            {shouldShowDetails(project.id) && (
              <div className="pt-3 mt-1 border-t border-dashed border-gray-100">
                {/* Top row with insight and status */}
                <div className="flex items-start justify-between">
                  {/* Insight on the left */}
                  {project.insight && (
                    <p className="text-gray-400 text-sm leading-normal flex-1">{project.insight}</p>
                  )}
                  
                  {/* Status and Money on the right - fixed flex layout */}
                  <div className="flex flex-row items-center gap-1.5 ml-3 shrink-0">
                    {/* Only show moneyDetails if it exists */}
                    {project.moneyDetails && (
                      <span className="text-xs font-medium px-2 py-0.5 rounded border border-gray-100 bg-gray-50 text-gray-500">
                        {project.moneyDetails}
                      </span>
                    )}
                    
                    <span className={`text-xs uppercase font-medium px-2 py-0.5 rounded border border-gray-100 ${getStatusClass(project.status)}`}>
                      {formatStatus(project.status)}
                    </span>
                  </div>
                </div>
                
                {/* Metric on its own row */}
                {project.metric && (
                  <div className="mt-2">
                    <p className="text-gray-400 text-sm leading-normal">{project.metric}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}