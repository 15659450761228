import React, { ReactElement } from 'react'

interface Props {

}

export default function Footer({ }: Props): ReactElement {
  return (
    <footer className="w-full mt-auto bg-white">
      <div className="footer py-4 px-4 md:px-6 max-w-3xl mx-auto">
        <ul className="flex flex-wrap justify-center md:justify-between gap-x-6 gap-y-2 text-sm">
          <li>
            <a href="mailto:christophe@vaneerdt.be" target="_blank" className="hover:underline">christophe@vaneerdt.be</a>
          </li>
          <li>
            <a href="tel:+32 479 70 63 42" target="_blank" className="hover:underline">+32 479 70 63 42</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/cvaneerdt/" target="_blank" className="hover:underline">LinkedIn</a>
          </li>
          <li className="cursor-default">VAT BE 06677 95 609</li>
        </ul>
      </div>
    </footer>
  )
}
